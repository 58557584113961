import { API } from 'aws-amplify';

const getActivityExport = (timeFrame, orgName, fileFormat, reportType) => {
  const activityExportData = {
    body: {
      when: timeFrame,
      who: orgName,
      format: fileFormat, // csv, html, pdf
      report: reportType, // call_log, user_feedback
    },
  };

  return API.post('AppHubAPI', 'activity/export', activityExportData)
    .then(async (response) => response);
};

export default getActivityExport;
